//故障返修申请
<template>
  <Page ref="page" v-loading="loading" :showReturn="!isCreate">
    <div>
      <el-form ref="form" label-width="140px" :model="params" :rules="rules">
        <div class="cm-flex-line">
          <el-form-item label="申请人：" prop="proposerCode">
            <SelectUserList
              :code.sync="params.proposerCode"
              :name.sync="params.proposerName"
              @change="proposerChange"
              :disabled="!isCreate"
            />
          </el-form-item>
          <el-form-item label="申请部门：" prop="applyDepartmentCode">
            <SelectDepartment
              ref="SelectDepartment"
              :code.sync="params.applyDepartmentCode"
              :name.sync="params.applyDepartmentName"
            />
          </el-form-item>
          <el-form-item label="申请时间：" prop="applyTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.applyTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now()
                }
              }"
            />
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="客户名称：" prop="clientName">
            <el-input
              v-model="params.clientName"
              placeholder="请输入客户名称"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="发货人：" prop="consigner">
            <el-input
              v-model="params.consigner"
              placeholder="请输入发货人"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="发货人联系方式：" prop="consignerMobile">
            <el-input
              v-model="params.consignerMobile"
              placeholder="请输入发货人联系方式"
              maxlength="11"
            ></el-input>
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="物流单号：" prop="trackingNumber">
            <el-input
              v-model="params.trackingNumber"
              placeholder="请输入物流单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="收货人：" prop="consignee">
            <el-input
              v-model="params.consignee"
              placeholder="请输入收货人"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="consigneeMobile">
            <el-input
              v-model="params.consigneeMobile"
              placeholder="请输入联系电话"
              maxlength="11"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="收货地址：" prop="shippingAddress">
          <el-input
            v-model="params.shippingAddress"
            placeholder="请输入收货地址"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <div class="cm-flex-line">
          <el-form-item label="加急维修：" prop="urgentMaintenance">
            <el-switch
              v-model="params.urgentMaintenance"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item label="加急快递：" prop="urgentExpress">
            <el-switch
              v-model="params.urgentExpress"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </div>
        <el-form-item label="备注：" prop="remark">
          <el-input
            v-model="params.remark"
            placeholder="请输入备注"
            type="textarea"
            maxlength="255"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="fileList" label="附件：">
          <UploadFile
            ref="UploadFile"
            v-model="params.fileList"
            :showAccept="false"
            :limit="3"
            multiple
            :preAppendixAddr="params && params.attachmentUrl"
          />
        </el-form-item>
        <el-divider content-position="left">申请物品清单</el-divider>
        <div style="display: flex; justify-content: end; margin-bottom: 10px">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addEvent()"
          >
            添加
          </el-button>
        </div>
        <el-table :data="params.detailUpdateInputs">
          <el-table-column header-align="center" label="设备料号">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.code'"
                :rules="rules.code"
              >
                <SelectGoodsCode
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="设备名称">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.name'"
                :rules="rules.name"
              >
                <SelectGoodsName
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="设备sn">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.sn'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].sn"
                  placeholder="设备sn"
                  oninput="value=value.replace(/[\u4e00-\u9fa5|\s]/g, '')"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="返修数量">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.num'"
                :rules="rules.num"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].num"
                  placeholder="请输入数量"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].num =
                        $format.fmtNumber(val))
                  "
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="是否在保">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.insuredStatus'"
                :rules="rules.insuredStatus"
              >
                <el-select
                  v-model="params.detailUpdateInputs[$index].insuredStatus"
                >
                  <el-option label="否" :value="0"></el-option>
                  <el-option label="是" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="故障详情">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.detail'"
                :rules="rules.detail"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].detail"
                  placeholder="请输入故障详情"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label=" ">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-button type="text" @click="deleteEvent($index)">
                  删除
                </el-button>
                <el-button type="text" @click="copyEvent($index)">
                  复制
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <template slot="empty">
            <div>暂无数据，请添加</div>
          </template>
        </el-table>
      </el-form>
    </div>
    <FlowableStep
      orderType="0"
      :variables="variables"
      @initNode="setNodes"
      @refresh="setAuditors"
    ></FlowableStep>
    <template slot="bottom">
      <el-button
        type="primary"
        @click="submitEvent(1)"
        style="margin-right: 10px"
      >
        提交申请
      </el-button>
      <el-button type="info" @click="submitEvent(2)">保存草稿</el-button>
    </template>
  </Page>
</template>

<script>
import FlowableStep from '@/components/FlowableStep'
import applyMixins from '../../mixins/applyMixins'
export default {
  mixins: [applyMixins],
  components: { FlowableStep },
  data() {
    return {
      rules: {
        proposerCode: [{ required: true, message: '请选择申请人' }],
        applyDepartmentCode: [{ required: true, message: '请选择申请人部门' }],
        applyTime: [{ required: true, message: '请选择申请日期' }],
        consigner: [{ required: true, message: '请输入发货人' }],
        consignerMobile: [
          { required: true, message: '请输入发货人联系方式' },
          { min: 11, max: 11, message: '请输入正确的手机号' }
        ],
        consignee: [{ required: true, message: '请输入收货人' }],
        trackingNumber: [{ required: true, message: '请输入物流单号' }],
        consigneeMobile: [
          { required: true, message: '请输入联系电话' },
          { min: 11, max: 11, message: '请输入正确的手机号' }
        ],
        shippingAddress: [{ required: true, message: '请输入收货地址' }],
        num: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || Number(value) === 0) {
                callback('请输入非0整数')
              } else {
                callback()
              }
            }
          }
        ],
        code: [{ required: true, message: '请选择' }],
        name: [{ required: true, message: '请选择' }],
        insuredStatus: [{ required: true, message: '请选择' }],
        detail: [{ required: true, message: '请输入故障详情' }]
      },
      params: {
        urgentExpress: 0,
        urgentMaintenance: 0,
        detailUpdateInputs: [],
        auditors: {}
      },
      nodes: [],
      variables: {
        WAREHOUSE_IN: '' // 条件参数值
      }
    }
  },
  computed: {
    isCreate() {
      return this.$route.query.edit != 1
    }
  },
  mounted() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    changeWarehouse(data) {
      this.variables['WAREHOUSE_IN'] = data.cwhCode
    },
    setNodes(nodes) {
      this.nodes = nodes
    },
    setAuditors(auditors) {
      this.params.auditors = auditors
    },
    async init() {
      try {
        const { orderCode } = this.$route.query
        const res = await this.$api.apply.faultRepairInfo({ orderCode })
        if (res) {
          const { orderResult, detailResults } = res
          orderResult.proposerCode = orderResult.proposerCode
            ? Number(orderResult.proposerCode)
            : null
          let params = {
            ...orderResult,
            detailUpdateInputs: detailResults,
            auditors: {}
          }
          this.params = params
        }
      } catch (err) {
        console.log(err)
      }
    },
    addEvent() {
      this.params.detailUpdateInputs.push({
        num: '',
        code: '',
        name: '',
        detail: '',
        insuredStatus: '',
        sn: ''
      })
    },
    deleteEvent(index) {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.filter(
        (a, i) => i !== index
      )
    },
    copyEvent(index) {
      let detail = this.params.detailUpdateInputs.find((a, i) => i === index)
      this.params.detailUpdateInputs.push({ ...detail })
    },
    submitEvent(submit) {
      if (this.loading) return
      let params = this.$utils.deepClone(this.params)
      if (params.applyTime) {
        params.applyTime = params.applyTime + ' 00:00:00'
      }
      if (params.fileList?.length > 0) {
        params.attachmentUrl = params.fileList
          .map(a => {
            return `${a.url}?fileName=${a.name}`
          })
          .join(',')
        delete params.fileList
      }
      let detailUpdateInputs = params.detailUpdateInputs
      delete params.detailUpdateInputs
      let newParams = {
        submit: submit == 1,
        detailUpdateInputs,
        updateInput: params
      }
      if (submit != 1) {
        try {
          this.loading = true
          this.$api.apply.faultRepairSave(newParams).then(result => {
            this.$message.success('草稿保存成功')
          })
        } finally {
          this.loading = false
        }
      } else {
        if (this.nodes.length) {
          let flag = true
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i].assigneeType == 'SELF_SELECT' &&
              this.nodes[i].userType == 2 &&
              this.nodes[i].users.length == 0 &&
              !this.params?.auditors[this.nodes[i].id]?.length
            ) {
              flag = false
              return this.$message.warning('请选择审批人后提交')
            }
          }
          console.log('flag', flag)
          if (!flag) return
        }
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              if (this.params.detailUpdateInputs.length === 0) {
                this.$message.warning('请至少添加一条物品清单')
                return
              }
              this.loading = true
              clearInterval(this.timeSaveFlag)
              await this.$api.apply.faultRepairSave(newParams)
              //提交成功
              this.submitSuccess('/#/maintainApply')
            } finally {
              this.loading = false
            }
          }
        })
      }
    },
    //申请人改变
    proposerChange(a) {
      this.$nextTick(() => {
        this.$refs.SelectDepartment?.changeEvent(a.cdepCode)
      })
    }
  }
}
</script>
